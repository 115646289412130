body {
  color: #444;
  font-family: Open Sans, sans-serif;
}

a {
  color: #1acc8d;
  text-decoration: none;
}

a:hover {
  color: #34e5a6;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
}

#preloader {
  z-index: 9999;
  background: #fff;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#preloader:before {
  content: "";
  width: 60px;
  height: 60px;
  border: 6px solid #1acc8d;
  border-top-color: #d2f9eb;
  border-radius: 50%;
  animation: animate-preloader 1s linear infinite;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.back-to-top {
  visibility: hidden;
  opacity: 0;
  z-index: 996;
  width: 40px;
  height: 40px;
  background: #1acc8d;
  border-radius: 50px;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.back-to-top i {
  color: #fff;
  font-size: 28px;
  line-height: 0;
}

.back-to-top:hover {
  color: #fff;
  background: #2be4a2;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

#header {
  height: 80px;
  z-index: 997;
  background: #010488e6;
  transition: all .5s;
}

#header.header-transparent {
  background: none;
}

#header.header-scrolled {
  height: 60px;
  background: #010488e6;
}

#header .logo h1 {
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
}

#header .logo h1 a, #header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  max-height: 40px;
  margin: 0;
  padding: 0;
}

.navbar {
  padding: 0;
}

.navbar ul {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.navbar li {
  position: relative;
}

.navbar a, .navbar a:focus {
  color: #ffffffb3;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 30px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 500;
  transition: all .3s;
  display: flex;
}

.navbar a i, .navbar a:focus i {
  margin-left: 5px;
  font-size: 12px;
  line-height: 0;
}

.navbar > ul > li > a:before {
  content: "";
  width: 0;
  height: 2px;
  visibility: hidden;
  width: 0;
  background-color: #1acc8d;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 3px;
  left: 30px;
}

.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
  visibility: visible;
  width: 25px;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #fff;
}

.navbar .dropdown ul {
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  border-radius: 4px;
  margin: 0;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  left: 30px;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  text-transform: none;
  color: #01036f;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #1acc8d;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navbar .dropdown .dropdown ul {
  visibility: hidden;
  top: 0;
  left: calc(100% - 30px);
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  line-height: 0;
  transition: all .5s;
  display: none;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  z-index: 999;
  background: #01035be6;
  transition: all .3s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 55px 15px 15px;
  overflow-y: auto;
}

.navbar-mobile > ul > li > a:before {
  left: 20px;
}

.navbar-mobile a, .navbar-mobile a:focus {
  color: #0205a1;
  padding: 10px 20px;
  font-size: 15px;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #3f43fd;
}

.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul, .navbar-mobile .dropdown .dropdown ul {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  margin: 10px 20px;
  padding: 10px 0;
  display: none;
  position: static;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar-mobile .dropdown ul li, .navbar-mobile .dropdown .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a, .navbar-mobile .dropdown .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i, .navbar-mobile .dropdown .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a, .navbar-mobile .dropdown .dropdown ul a:hover, .navbar-mobile .dropdown .dropdown ul .active:hover, .navbar-mobile .dropdown .dropdown ul li:hover > a {
  color: #1acc8d;
}

.navbar-mobile .dropdown > .dropdown-active, .navbar-mobile .dropdown .dropdown > .dropdown-active {
  display: block;
}

#hero {
  width: 100%;
  background: url("hero-bg.203c2a73.jpg");
  padding: 120px 0 0;
  position: relative;
}

#hero:before {
  content: "";
  background: #0205a1e8;
  position: absolute;
  inset: 0;
}

#hero h1 {
  color: #fffc;
  margin: 0 0 20px;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

#hero h1 span {
  color: #fff;
  border-bottom: 4px solid #1acc8d;
}

#hero h2 {
  color: #fffc;
  margin-bottom: 40px;
  font-size: 24px;
}

#hero .btn-get-started {
  letter-spacing: 1px;
  color: #fff;
  background: #1acc8d;
  border-radius: 50px;
  padding: 10px 30px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: all .5s;
  display: inline-block;
}

#hero .btn-get-started:hover {
  background: #17b57d;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    padding-top: 80px;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    max-width: 80%;
  }

  #hero h1 {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 32px;
  }

  #hero h2 {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.hero-waves {
  width: 100%;
  height: 60px;
  z-index: 5;
  margin-top: 60px;
  display: block;
  position: relative;
}

.wave1 use {
  animation: move-forever1 10s linear -2s infinite;
}

.wave2 use {
  animation: move-forever2 8s linear -2s infinite;
}

.wave3 use {
  animation: move-forever3 6s linear -2s infinite;
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px);
  }

  100% {
    transform: translate(-90px);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px);
  }

  100% {
    transform: translate(85px);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px);
  }

  100% {
    transform: translate(85px);
  }
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f5ff;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaa;
  margin: 0 0 5px;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1px;
}

.section-title h2:after {
  content: "";
  width: 120px;
  height: 1px;
  background: #1acc8d;
  margin: 4px 10px;
  display: inline-block;
}

.section-title p {
  text-transform: uppercase;
  color: #010483;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.breadcrumbs {
  min-height: 40px;
  background-color: #fafaff;
  margin-top: 80px;
  padding: 20px 0;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 64px;
  }
}

.breadcrumbs h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px;
  }
}

.breadcrumbs ol {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  display: flex;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li:before {
  color: #6c757d;
  content: "/";
  padding-right: 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

.about {
  padding: 40px 0 0;
}

.about .icon-boxes h3 {
  color: #010483;
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 700;
}

.about .icon-box {
  margin-top: 40px;
}

.about .icon-box .icon {
  float: left;
  width: 64px;
  height: 64px;
  border: 2px solid #7ceec6;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
}

.about .icon-box .icon i {
  color: #1acc8d;
  font-size: 32px;
}

.about .icon-box:hover .icon {
  background: #1acc8d;
  border-color: #1acc8d;
}

.about .icon-box:hover .icon i {
  color: #fff;
}

.about .icon-box .title {
  margin-bottom: 10px;
  margin-left: 85px;
  font-size: 18px;
  font-weight: 700;
}

.about .icon-box .title a {
  color: #343a40;
  transition: all .3s;
}

.about .icon-box .title a:hover {
  color: #1acc8d;
}

.about .icon-box .description {
  margin-left: 85px;
  font-size: 14px;
  line-height: 24px;
}

.about .video-box {
  min-height: 300px;
  background: url("https://cdn.discordapp.com/attachments/947848349476876389/1094637348521775194/gamingAppThumbnail.png") center / contain no-repeat;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#3f43fd 50%, #3f43fd66 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: calc(50% - 47px);
  left: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:after {
  content: "";
  width: 0;
  height: 0;
  z-index: 100;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 15px solid #fff;
  transition: all .4s cubic-bezier(.55, .055, .675, .19);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-40%)translateY(-50%);
}

.about .play-btn:before {
  content: "";
  width: 120px;
  height: 120px;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  background: #c6100000;
  border: 5px solid #3f43fdb3;
  border-radius: 50%;
  position: absolute;
  top: -15%;
  left: -15%;
}

.about .play-btn:hover:after {
  border-left: 15px solid #3f43fd;
  transform: scale(20);
}

.about .play-btn:hover:before {
  content: "";
  width: 0;
  height: 0;
  z-index: 200;
  border: 10px solid #0000;
  border-left: 15px solid #fff;
  border-right: none;
  border-radius: 0;
  animation: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-40%)translateY(-50%);
}

@keyframes pulsate-btn {
  0% {
    opacity: 1;
    transform: scale(.6);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.features .icon-box {
  background: #f5f5ff;
  align-items: center;
  padding: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.features .icon-box i {
  padding-right: 10px;
  font-size: 32px;
  line-height: 1;
}

.features .icon-box h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

.features .icon-box h3 a {
  color: #010483;
  transition: all .3s ease-in-out;
}

.features .icon-box h3 a:hover {
  color: #01036f;
}

.features .icon-box:hover {
  background: #ebebff;
}

.counts {
  background: #f5f5ff;
  padding: 70px 0 60px;
}

.counts .count-box {
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 30px 30px 25px;
  position: relative;
}

.counts .count-box i {
  color: #fff;
  width: 48px;
  height: 48px;
  background: #1acc8d;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 20px;
  line-height: 0;
  display: inline-flex;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.counts .count-box span {
  color: #010483;
  font-size: 36px;
  font-weight: 600;
  display: block;
}

.counts .count-box p {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
}

.details .content + .content {
  margin-top: 100px;
}

.details .content h3 {
  color: #010483;
  font-size: 26px;
  font-weight: 600;
}

.details .content ul {
  padding: 0;
  list-style: none;
}

.details .content ul li {
  padding-bottom: 10px;
}

.details .content ul i {
  color: #1acc8d;
  padding-right: 4px;
  font-size: 20px;
}

.details .content p:last-child {
  margin-bottom: 0;
}

.gallery .gallery-item {
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  overflow: hidden;
}

.gallery .gallery-item img {
  transition: all .4s ease-in-out;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

.testimonials {
  background: url("cta-bg.157ff3dd.jpg") center / cover no-repeat;
  padding: 80px 0;
  position: relative;
}

.testimonials:before {
  content: "";
  background: #01036fcc;
  position: absolute;
  inset: 0;
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel, .testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border: 6px solid #ffffff26;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  color: #fff;
  margin: 10px 0 5px;
  font-size: 20px;
  font-weight: bold;
}

.testimonials .testimonial-item h4 {
  color: #ddd;
  margin: 0 0 15px;
  font-size: 14px;
}

.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #fff6;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  position: relative;
  left: -5px;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  position: relative;
  top: 10px;
  right: -5px;
}

.testimonials .testimonial-item p {
  color: #eee;
  margin: 0 auto 15px;
  font-style: italic;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: #fff6;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1acc8d;
}

@media (min-width: 1024px) {
  .testimonials {
    background-attachment: fixed;
  }
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

.team {
  background: #fff;
}

.team .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.team .member .pic {
  border-radius: 4px;
  overflow: hidden;
}

.team .member img {
  transition: all .4s ease-in-out;
}

.team .member:hover img {
  transform: scale(1.1);
}

.team .member .member-info {
  background: #ffffffe6;
  border-radius: 0 0 4px 4px;
  padding: 15px 0;
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  box-shadow: 0 2px 15px #0000001a;
}

.team .member h4 {
  color: #01036f;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
}

.team .member h4:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #0d12fc;
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(50% - 25px);
}

.team .member span {
  color: #01036f;
  font-size: 13px;
  font-style: italic;
  display: block;
}

.team .member .social {
  margin-top: 10px;
}

.team .member .social a {
  color: #01036f;
  transition: color .3s;
}

.team .member .social a:hover {
  color: #1acc8d;
}

.team .member .social i {
  margin: 0 2px;
  font-size: 16px;
}

.pricing .box {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 4px #0000001f;
}

.pricing h3 {
  color: #777;
  background: #f8f8f8;
  margin: -20px -20px 20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
}

.pricing h4 {
  color: #1acc8d;
  margin-bottom: 20px;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 600;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  color: #444;
  text-align: center;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  list-style: none;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #1acc8d;
  padding-right: 4px;
  font-size: 18px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  text-align: center;
  background: #f8f8f8;
  margin: 20px -20px -20px;
  padding: 20px 15px;
}

.pricing .btn-buy {
  color: #fff;
  background: #1acc8d;
  border-radius: 50px;
  padding: 8px 35px 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s;
  display: inline-block;
}

.pricing .btn-buy:hover {
  background: #149f6e;
}

.pricing .featured h3 {
  color: #fff;
  background: #1acc8d;
}

.pricing .advanced {
  width: 200px;
  z-index: 1;
  color: #fff;
  background: #1acc8d;
  padding: 1px 0 3px;
  font-size: 14px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
}

.faq .faq-list {
  padding: 0;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.faq .faq-list a {
  cursor: pointer;
  outline: none;
  padding: 0 30px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  position: relative;
}

.faq .faq-list .icon-help {
  color: #34e5a6;
  font-size: 24px;
  position: absolute;
  left: 20px;
  right: 0;
}

.faq .faq-list .icon-show, .faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #1acc8d;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  color: #3f43fd;
  float: left;
  width: 44px;
  height: 44px;
  background: #f0f0ff;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.contact .info h4 {
  color: #010483;
  margin-bottom: 5px;
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
}

.contact .info p {
  color: #0205a1;
  margin-bottom: 0;
  padding: 0 0 0 60px;
  font-size: 14px;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  color: #fff;
  background: #1acc8d;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  color: #fff;
  text-align: left;
  background: #ed3c0d;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  color: #fff;
  text-align: center;
  background: #18d26e;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .loading {
  text-align: center;
  background: #fff;
  padding: 15px;
  display: none;
}

.contact .php-email-form .loading:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  border-radius: 50%;
  margin: 0 10px -6px 0;
  animation: animate-loading 1s linear infinite;
  display: inline-block;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  color: #fff;
  background: #1acc8d;
  border: 0;
  border-radius: 50px;
  padding: 10px 30px;
  transition: all .4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #34e5a6;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#footer {
  color: #fff;
  background: #010351;
  padding: 0 0 30px;
  font-size: 14px;
}

#footer .footer-top {
  padding: 60px 0 30px;
}

#footer .footer-top .footer-info {
  color: #fff;
  text-align: center;
  background: #010246;
  border-top: 4px solid #1acc8d;
  margin-bottom: 15px;
  padding: 30px 20px;
}

#footer .footer-top .footer-info h3 {
  margin: 0 0 20px;
  padding: 2px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
}

#footer .footer-top .footer-info p {
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

#footer .footer-top .social-links a {
  color: #fff;
  text-align: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 4px;
  padding: 8px 0;
  font-size: 18px;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

#footer .footer-top .social-links a:hover {
  color: #fff;
  background: #1acc8d;
  text-decoration: none;
}

#footer .footer-top h4 {
  color: #fff;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#footer .footer-top .footer-links ul i {
  color: #61ebba;
  padding-right: 2px;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  align-items: center;
  padding: 10px 0;
  display: flex;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

#footer .footer-top .footer-links ul a:hover {
  color: #4be8b0;
}

#footer .footer-top .footer-newsletter form {
  background: #fff;
  border-radius: 50px;
  margin-top: 30px;
  padding: 6px 10px 6px 15px;
  position: relative;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  width: calc(100% - 110px);
  border: 0;
  padding: 4px;
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  color: #fff;
  background: #1acc8d;
  border: 0;
  border-radius: 50px;
  padding: 0 20px;
  font-size: 16px;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2px;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #149f6e;
}

#footer .copyright {
  text-align: center;
  border-top: 1px solid #010479;
  padding-top: 30px;
}

#footer .credits {
  text-align: center;
  color: #fff;
  padding-top: 10px;
  font-size: 13px;
}

@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px;
  }
}

/*# sourceMappingURL=index.da308a3a.css.map */
